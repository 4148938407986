import { useEffect, useMemo, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_PT_BR } from 'mantine-react-table/locales/pt-BR';
import apiInstance from '../services/api_service';
import { Box } from '@mantine/core';

const Users = () => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const params = {
        page_size: pagination.pageSize,
        page: pagination.pageIndex + 1,
      }
      columnFilters.forEach((filter, index) => {
        switch (filter.id) {
          case 'first_name':
            params[`first_name__icontains`] = filter.value;
            break;
          case 'last_name':
            params[`last_name__icontains`] = filter.value;
            break;
          case 'email':
            params[`email__icontains`] = filter.value;
            break;
            // if(filter.value[0]) {
            //   params[`amount_min`] = filter.value[0];
            // }
            // if(filter.value[1]) {
            //   params[`amount_max`] = filter.value[1];
            // }
            // break;
          default:
            break;
        }
      });
      if (globalFilter) {
        params[`search`] = globalFilter;
      } 
      const queryString = new URLSearchParams(params).toString();
      try {
        const response = await apiInstance.get(`users/?${queryString}`);
        setData(response.data.results);
        setRowCount(response.data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters, //refetch when column filters change
    globalFilter, //refetch when global filter changes
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'profile.external_image_link',
        header: 'Foto',
        size: 30,
        Header: ({ column }) => (
          <></>
        ),
        Filter: ({ column }) => (
          <></>
        ),
        enableFilters: false,
        enableColumnFilters: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            { renderedCellValue ? (
              <img class='relative w-12 h-12 rounded-full' src={renderedCellValue} alt='Rounded Avatar'></img> 
              ) : (
              <div class='relative w-12 h-12 bg-gray-100 flex justify-center items-center rounded-full'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15.9998 7C15.9998 9.20914 14.2089 11 11.9998 11C9.79067 11 7.99981 9.20914 7.99981 7C7.99981 4.79086 9.79067 3 11.9998 3C14.2089 3 15.9998 4.79086 15.9998 7Z" stroke="#4B5563" stroke-width="1.6" />
                <path d="M11.9998 14C9.15153 14 6.65091 15.3024 5.23341 17.2638C4.48341 18.3016 4.10841 18.8204 4.6654 19.9102C5.2224 21 6.1482 21 7.99981 21H15.9998C17.8514 21 18.7772 21 19.3342 19.9102C19.8912 18.8204 19.5162 18.3016 18.7662 17.2638C17.3487 15.3024 14.8481 14 11.9998 14Z" stroke="#4B5563" stroke-width="1.6" />
                </svg>
              </div>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 30,
        maxSize: 30,
      },
      {
        accessorKey: 'first_name',
        header: 'Nome',
      },
      {
        accessorKey: 'last_name',
        header: 'Sobrenome',
        // Cell: ({ cell }) => {
        //   return cell.getValue().toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        // },
        // filterVariant: 'range',
        // filterFn: 'betweenInclusive',
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableColumnActions: false,
    // getRowId: (row) => row.phoneNumber,
    initialState: { 
      showColumnFilters: true 
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableFilterMatchHighlighting: true,
    localization: MRT_Localization_PT_BR,
    //columnFilterDisplayMode: 'popover',
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: 'red', children: 'Error loading data' }
      : undefined,
  });

  return (
    <div>
      <MantineReactTable table={table} />
    </div>
  );
};

export default Users;
