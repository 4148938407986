import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message";
import apiInstance from "../services/api_service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleLoginComponent from "../components/GoogleLogin";
import useAuth from "../hooks/useAuth";


const Signin = () => {

  const { register, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const { checkAuth } = useAuth();

  const onSubmit = async (formData) => {
    const payload = {
      email: formData.email,
      password: formData.password,
    };
    const response = await apiInstance.post(
      'authentication/user/login',
      payload, {headers: {'Content-Type': 'application/json'}}
    ).catch((error) => {
      if (error.status === 401) {
        const { data } = error.response;
        let delay = 0;
        for (const key in data) {
          const value = data[key];
          toast.error(<div>{`${value.toLowerCase()}`}</div>, {
            position: "top-right",
            delay: delay
          });
          delay += 500;
        }
      }
    });

    if (response === undefined) {
      return;
    }
    
    sessionStorage.clear();

    function goToHome() {
      window.location.replace('/')
    } 
    toast.success("Login successful", {
      position: "top-right",
      autoClose: 800,
      onClose: () => goToHome()
    });
  }
  
  
  return (
    <div className={'mainContainer'}>
      <ToastContainer />
      <div className={'titleContainer'}>
        <div>Sign in</div>
      </div>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'inputContainer'}>
          <input {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
            placeholder="Enter your email here"
            className={'inputBox'}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <label key={type} className="errorLabel">{message}</label>
                  ))
                : null;
            }}
          />
        </div>
        <br />
        <div className={'inputContainer'}>
          <input {...register("password", {
              required: "Password is required",
            })}
            type='password'
            placeholder="Enter your password here"
            className={'inputBox'}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ messages }) => {
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                  <label key={type} className="errorLabel">{message}</label>
                  ))
                : null;
            }}
          />
        </div>
        <br />
        <div className={`inputContainer inputSubmitContainer`}>
          <input className={'inputButton'} type="submit" value={'Log in'} />
        </div>
      </form>
      <br />
      <GoogleLoginComponent />
    </div>
  )
}

export default Signin