import React, { useEffect, useState } from "react";
import { useUser } from "./contexts/UserContext";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom'
import useAuth from "./hooks/useAuth";
import Home from './pages/Home'
import Signin from './pages/Signin'
import Splash from './pages/Splash'
import Signup from './pages/Signup'
import Users from './pages/Users'
import './App.css'
import SideBar from './components/SideBar'
import Header from './components/Header'

const Layout = ({ isAuthenticated }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const { user, fetchUser } = useUser();
  const handleViewSidebar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const closeSideBar = () => {
    setIsSideBarOpen(false);
  }

  useEffect(() => {
      if (!user) {
          fetchUser();
      }
  }, [user, fetchUser]);

  if (!isAuthenticated) return <Navigate to="/signin" replace />;

  return (
    <div className="h-screen flex flex-col lg:flex-row">
      <SideBar isSideBarOpen={isSideBarOpen} toggleSidebar={handleViewSidebar} closeSideBar={closeSideBar}/>
      <div className={`flex flex-col flex-1`}>
        <Header controlSideBar={handleViewSidebar}/>
        <main className="pt-28 overflow-auto h-full z-0 relative px-12 pb-12">
          <Outlet/>
        </main>
      </div>
    </div>
  )
}

function App() {

  const { isAuthenticated, loading } = useAuth();

  if (loading) return;

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout isAuthenticated={isAuthenticated} />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/users',
          element: <Users />
        },
        {
          path: '/splash',
          element: <Splash />
        },
      ]
    },
    {
      path: '/signin',
      element: <Signin />
    },
    {
      path: '/signup',
      element: <Signup />
    }
  ])

  return (
    <RouterProvider router={router}/>
  )
}

export default App