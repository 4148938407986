import { useUser } from "../contexts/UserContext";

const Home = () => {  
  const { user, fetchUser } = useUser();

  return (
    <div className="mainContainer">
      <div className={'titleContainer'}>
        <h1 className='text-5xl font-bold'>Welcome!</h1>
      </div>
      <div>This is the home page.</div>
      <div className={'buttonContainer'}>
        {
          user?.email ? (
            <>
              <div>Your email address is {user.email}</div>
            </>
          ) : null
        }
      </div>
    </div>
  )
}

export default Home