import React, { createContext, useState, useContext } from "react";
import apiInstance from "../services/api_service";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    
    const fetchUser = async () => {
        try {
            const response = await apiInstance.get("authentication/user/data");
            setUser(response.data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, fetchUser }}>
            {children}
        </UserContext.Provider>
    );
};
