 import { Link } from 'react-router-dom'
 import { useNavigate } from 'react-router-dom'
import apiInstance from '../services/api_service';


export default function SideBar(props) {
  const sidebarClass = props.isSideBarOpen ? "" : "-translate-x-full";

  const navigate = useNavigate()

  const logoutUser = async () => {
    try {
      await apiInstance.get('authentication/user/logout');
      navigate("/signin");
    } catch (err) {
      console.error(err);
      navigate("/signin");
    } 
  }

  return (
    <aside id="sidebar" className={`z-30 fixed lg:static lg:block transform ${sidebarClass} lg:translate-x-0 transition-transform duration-300 w-64 h-full`}>
      <div className="fixed h-full w-64 bg-white border-r border-gray-200">
        <div className="w-full flex gap-4">
          <div className="p-4 bg-white flex-col justify-start items-start gap-6 inline-flex">
            <div className="w-full justify-between items-center gap-2.5 inline-flex">
                <img src="https://pagedone.io/asset/uploads/1701235273.png"></img>
                {/* <a href="javascript:;" className="w-6 h-6 relative bg-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Menu">
                            <rect width="24" height="24" fill="white" />
                            <path id="icon" d="M13 6H21M3 12H21M7 18H21" stroke="#1F2937" stroke-width="1.6" stroke-linecap="round" />
                        </g>
                    </svg>
                </a> */}
                <button onClick={props.closeSideBar} className='lg:hidden'>X</button>
            </div>
            <div className="w-full">
                <div className="w-full h-8 px-3 items-center flex">
                    <h6 className="text-gray-500 text-xs font-semibold leading-4">MENU</h6>
                </div>
                <ul className="flex-col gap-1 flex">
                    <li>
                        <Link to="/">
                            <div className="flex-col flex p-3 bg-white rounded-lg">
                              <div className="h-5 gap-3 flex">
                                <div class="relative">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g id="category 02">
                                      <g id="icon">
                                        <path d="M2.5 5.41667C2.5 3.80584 3.80584 2.5 5.41667 2.5C7.0275 2.5 8.33333 3.80584 8.33333 5.41667C8.33333 7.0275 7.0275 8.33333 5.41667 8.33333C3.80584 8.33333 2.5 7.0275 2.5 5.41667Z" stroke="#6B7280" stroke-width="1.6" />
                                        <path d="M11.6667 5.41667C11.6667 4.24628 11.6667 3.66109 11.9476 3.24072C12.0691 3.05873 12.2254 2.90248 12.4074 2.78088C12.8278 2.5 13.4129 2.5 14.5833 2.5C15.7537 2.5 16.3389 2.5 16.7593 2.78088C16.9413 2.90248 17.0975 3.05873 17.2191 3.24072C17.5 3.66109 17.5 4.24628 17.5 5.41667C17.5 6.58705 17.5 7.17224 17.2191 7.59262C17.0975 7.7746 16.9413 7.93085 16.7593 8.05245C16.3389 8.33333 15.7537 8.33333 14.5833 8.33333C13.4129 8.33333 12.8278 8.33333 12.4074 8.05245C12.2254 7.93085 12.0691 7.7746 11.9476 7.59262C11.6667 7.17224 11.6667 6.58705 11.6667 5.41667Z" stroke="#6B7280" stroke-width="1.6" />
                                        <path d="M11.6667 14.5833C11.6667 12.9725 12.9725 11.6667 14.5833 11.6667C16.1942 11.6667 17.5 12.9725 17.5 14.5833C17.5 16.1942 16.1942 17.5 14.5833 17.5C12.9725 17.5 11.6667 16.1942 11.6667 14.5833Z" stroke="#6B7280" stroke-width="1.6" />
                                        <path d="M2.5 14.5833C2.5 13.4129 2.5 12.8278 2.78088 12.4074C2.90248 12.2254 3.05873 12.0691 3.24072 11.9476C3.66109 11.6667 4.24628 11.6667 5.41667 11.6667C6.58705 11.6667 7.17224 11.6667 7.59262 11.9476C7.7746 12.0691 7.93085 12.2254 8.05245 12.4074C8.33333 12.8278 8.33333 13.4129 8.33333 14.5833C8.33333 15.7537 8.33333 16.3389 8.05245 16.7593C7.93085 16.9413 7.7746 17.0975 7.59262 17.2191C7.17224 17.5 6.58705 17.5 5.41667 17.5C4.24628 17.5 3.66109 17.5 3.24072 17.2191C3.05873 17.0975 2.90248 16.9413 2.78088 16.7593C2.5 16.3389 2.5 15.7537 2.5 14.5833Z" stroke="#6B7280" stroke-width="1.6" />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <h2 class="text-gray-500 text-sm font-medium leading-snug">Home</h2>
                              </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/users">
                          <div class="flex-col gap-1 flex">
                            <div class="flex-col flex p-3 bg-white rounded-lg">
                              <div class="h-5 gap-3 flex">
                                <div class="relative">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="People 04">
                                    <path d="M8 20.0024V15.0024M8 20.0024C8 20.5547 8.44772 21.0024 9 21.0024H15C15.5523 21.0024 16 20.5547 16 20.0024M8 20.0024H3C2.44772 20.0024 2 19.5547 2 19.0024V15.0024C2 13.3456 3.34315 12.0024 5 12.0024C6.65685 12.0024 8 13.3456 8 15.0024M16 20.0024V15.0024M16 20.0024H21C21.5523 20.0024 22 19.5547 22 19.0024V15.0024C22 13.3456 20.6569 12.0024 19 12.0024C17.3431 12.0024 16 13.3456 16 15.0024M16 15.0024C16 13.3456 14.6569 12.0024 13 12.0024H11C9.34315 12.0024 8 13.3456 8 15.0024M15 6.00244C15 7.6593 13.6569 9.00244 12 9.00244C10.3431 9.00244 9 7.6593 9 6.00244C9 4.34559 10.3431 3.00244 12 3.00244C13.6569 3.00244 15 4.34559 15 6.00244ZM6.5 7.50244C6.5 8.33087 5.82843 9.00244 5 9.00244C4.17157 9.00244 3.5 8.33087 3.5 7.50244C3.5 6.67401 4.17157 6.00244 5 6.00244C5.82843 6.00244 6.5 6.67401 6.5 7.50244ZM20.5 7.50244C20.5 8.33087 19.8284 9.00244 19 9.00244C18.1716 9.00244 17.5 8.33087 17.5 7.50244C17.5 6.67401 18.1716 6.00244 19 6.00244C19.8284 6.00244 20.5 6.67401 20.5 7.50244Z" stroke="#6B7280" stroke-width="1.6" class="my-path"></path>
                                    </g>
                                  </svg>
                                </div>
                                <h2 class="text-gray-500 text-sm font-medium leading-snug">Usuários</h2>
                              </div>
                            </div>
                          </div>
                        </Link>
                    </li>
                </ul>
            </div>
            <div class="w-full flex-col flex">
                <div class="h-8 px-3 items-center inline-flex">
                    <h6 class="text-gray-500 text-xs font-semibold leading-4">CONFIGURAÇÕES</h6>
                </div>
                <ul class="flex-col gap-1 flex">
                  <li>
                    <Link>
                      <div class="p-3 rounded-lg items-center inline-flex" onClick={logoutUser}>
                          <div class="h-5 items-center gap-3 flex">
                              <div class="relative">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                      <g id="Logout">
                                          <path id="icon" d="M9.16667 17.5L5.83333 17.5V17.5C3.98765 17.5 2.5 16.0123 2.5 14.1667V14.1667L2.5 5.83333V5.83333C2.5 3.98765 3.98765 2.5 5.83333 2.5V2.5L9.16667 2.5M8.22814 10L17.117 10M14.3393 6.66667L17.0833 9.41074C17.3611 9.68852 17.5 9.82741 17.5 10C17.5 10.1726 17.3611 10.3115 17.0833 10.5893L14.3393 13.3333" stroke="#6B7280" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                      </g>
                                  </svg>
                              </div>
                              <h2 class="text-gray-500 text-sm font-medium leading-snug">Sair</h2>
                          </div>
                      </div>
                    </Link>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}