import companyLogo from '../assets/company-logo.png';
import poepleFinance from '../assets/people-finance.jpg';

const Splash = () => {
    return (
      <div className="bg-gray-100">
        <div className="px-8 py-12 max-w-md mx-auto sm:max-w-xl">
          <img className="h-20" src={companyLogo} alt="" />
          <img className="mt-10 rounded-lg shadow-xl sm:mt-8 sm:h-64 sm:w-full sm:object-cover" src={poepleFinance} alt="" />
          <h1 className="mt-6 text-2xl font-bold text-gray-900 leading-tight">
            Track your money usage with this 
            <span className="text-orange-500"> smart tool</span>
          </h1>
          <p className="mt-2 text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="mt-4">
            <a href="#" className="bg-orange-500 hover:bg-orange-400 focus:outline-none focus:shadow-outline px-5 py-3 text-white text-sm rounded-lg shadow-lg uppercase tracking-wider font-semibold">Try it now for free</a>
          </div>
        </div>
      </div>
    )
}


export default Splash